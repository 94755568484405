import { dew as _resourceLoaderDew } from "./resource-loader.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const ResourceLoader = _resourceLoaderDew();

  exports = class NoOpResourceLoader extends ResourceLoader {
    fetch() {
      return null;
    }

  };
  return exports;
}